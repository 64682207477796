import React,{useState} from 'react';
import Header from '../../components/Header/Header';
import Banner from '../../components/Banner/Banner';
import Footer from '../../components/Footer/Footer';
import ArticleInfo from '../../components/ArticleInfo/ArticleInfo';


const CallUs = (props) =>{

    return <div className="CallUs">
      <Header></Header>
      <Banner height='317' img='https://oss.5rs.me/oss/uploadfe/png/cbe7b52b04dfcb150a0585bec77cdda0.png?_=7802'></Banner>
      <ArticleInfo></ArticleInfo>
       <Footer></Footer>
    </div>
}

export default CallUs;