import React from 'react';
import { Link,navigate} from "gatsby"

import fetchApi from "@/api"
import Utils from '@/utils/utils';


import "./ArticleInfo.scss"

export default class ArticleInfo extends React.PureComponent {
  id=0;
  constructor(props) {
    super(props)
    this.state = {
      articleInfo: {},
      categoryInfo:{},
      categoryIndex:0,
      category2:'',
      data1:[],
      data2:[]
    }
    const urlQuery=Utils.urlQuery();
    if(urlQuery && urlQuery['id']){
      this.id=urlQuery['id'];
    }
  
    
  }

  fetchApi = async (params) => {
    let data = await fetchApi.getArticleInfo(params)
    if(!data.category)
      data.category=0;
    console.log(data)
    this.setState({
      articleInfo: data || {}
    })
    this.fetchApiMore(5,1);
    const category2=data.category!=4?data.category+1:1;
    this.fetchApiMore(6,2);
    this.setState({
      category2
    })

  }

  fetchApiMore = async (category,index) => {
    const res = await fetchApi.getArticleList({ 
      currentPage: 0, 
      isClient:1,
      numPerPage: 6,
      category})
    let data= (res && res.recordList)?res.recordList:[];
    // data.sort((a,b)=>{
    //   return new Date(b.publishTime.replace(/\-/g,'/'))-new Date(a.publishTime.replace(/\-/g,'/'));
    // })
    this.setState({
      ['data'+(index)]: data,
    })
    if(window.location.hash){
      window.location.href=window.location.href;
    }

  }

  componentDidMount() {

    this.fetchApi({id:this.id});


    // if (pathname.split('/')[1] === 'dynamic') {
    //   this.fetchApi({ id: search.split('=')[1] })
    // } else {
    //   this.fetchApi({ id: search.split('=')[1], type: 'IMPORTANT_NOTICE' })
    // }
    
  }

  navigateTo=(id)=>{
    //console.log('/dynamic/info?id='+id);
    navigate('?id='+id);
    this.fetchApi({id});
    //navigate('/dynamic/info?id='+id);
    //window.location.href='/dynamic/info?id='+id;
  }

  render() {
    const { articleInfo,data1,data2,category2} = this.state
    
    const categoryName=CATEGORY[articleInfo.category] && CATEGORY[articleInfo.category].category;
    return (
      <div className="ArticleInfo">
        <div className="article-main">
          <div className="article-left">
            <div className="bread-nav">
              <span  className="toPrev">{categoryName}</span><span className="to">&gt;</span><span className="small-title">文章详情</span>
              {/* <Link className="toPrev" to={pathname.split('/')[1] === 'dynamic' ? '/dynamic' : '/important'}>{pathname.split('/')[1] === 'dynamic' ? '市场动态' : '重要通知'}</Link><span className="to">></span><span className="small-title">文章详情</span> */}
            </div>
            <h3 className="main-title">{articleInfo.mainTitle}</h3>
            <p className="article-time">{articleInfo.publishTime}</p>
            <h3 className="second-title ">{articleInfo.secondTitle}</h3>
            <div className="article-content" dangerouslySetInnerHTML={{ __html: articleInfo.content }}>
            </div>
            {/* <div><img style={{width:'100%'}} src={articleInfo.img?articleInfo.img:actionImg}/></div> */}
            
          </div>

          <div className="article-right">
           <>
              <h5>{CATEGORY[5].category}</h5>
              <ul className="more-article">
                {
                data1.map((item,index)=>(<li key={index} onClick={e=>this.navigateTo(item.id)}>
                  <div className="cover">
                    <img src={item.cover}/>
                  </div>
                  <div className="content">
                    <p>{item.mainTitle}</p>
                    <small>{item.publishTime}</small>
                  </div>
                </li>))
                }
              </ul>
            </>
          
              <h5>{CATEGORY[6].category}</h5>
              <ul className="more-article">
                {
                data2.map((item,index)=>(<li key={index} onClick={e=>this.navigateTo(item.id)}>
                  <div className="cover">
                    <img src={item.cover}/>
                  </div>
                  <div className="content">
                    <p>{item.mainTitle}</p>
                    <small>{item.publishTime}</small>
                  </div>
                </li>))
                }
              </ul>
          </div>
        </div>
        <div className="article-line m1200"></div>

        {/* <div className="next-or-prev">
          <Link className="prev" onClick={() => this.fetchApi({ id: articleInfo.preUpdateId })} to={ `?id=${articleInfo.preUpdateId}`}>
            <p className="next-or-prev-title">{!articleInfo.preUpdateId ? '没有上一篇啦' : '上一篇'}</p>
            <p className="next-orPrev-titleContet">{!articleInfo.preUpdateId ? '' : articleInfo.preUpdateTitle}</p>
          </Link>
          <Link className="next" onClick={() => this.fetchApi({ id: articleInfo.nextUpdateId })} to={`?id=${articleInfo.nextUpdateId}`}>
            <p className="next-or-prev-title">{!articleInfo.nextUpdateId ? '没有下一篇啦' : '下一篇'}</p>
            <p className="next-orPrev-titleContet">{!articleInfo.nextUpdateId ? '' : articleInfo.nextUpdateTitle}</p>
          </Link>
        </div> */}


      </div>
      
    )
  }

}

const CATEGORY = [
  {
    category:""
  },
  {
    category: "市场动态"
  },
  {
    category: "编辑创新培训"
  },
  {
    category: "媒体报道"
  },
  {
    category: "基金投决公示"
  },
  {
    category: "获奖作品"
  },
  {
    category: "大赛通知"
  }
]
